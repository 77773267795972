import { forwardRef } from 'react'

export const TextInput = forwardRef((props, ref) => {
  const { className, ..._props } = props
  return (
    <div className="text-left">
      {_props.label && (
        <label className="block text-sm text-gray-700" htmlFor={_props.name}>
          {_props.label}
          {_props.required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={`flex space-x-2 items-center ${className || ''}`}>
        <input
          ref={ref}
          type="text"
          className="w-full px-2 py-1 text-gray-700 border-gray-300 rounded disabled:bg-gray-50 disabled:text-gray-500"
          {..._props}
        />
      </div>
    </div>
  )
})
TextInput.displayName = 'Text Input'

export const NumberInput = forwardRef((props, ref) => {
  const { className, ..._props } = props
  return (
    <div className="text-left">
      {_props.label && (
        <label className="block text-sm text-gray-700" htmlFor={_props.name}>
          {_props.label}
          {_props.required && <span className="text-red-500">*</span>}
        </label>
      )}

      <div className={`flex space-x-2 items-center ${className}`}>
        <input
          ref={ref}
          {..._props}
          type="number"
          className="w-full px-2 py-1 text-gray-700 border-gray-300 rounded disabled:bg-gray-50 disabled:text-gray-500"
        />
      </div>
    </div>
  )
})
NumberInput.displayName = 'Number Input'

export const TextArea = forwardRef((props, ref) => {
  const { className, ..._props } = props
  return (
    <div className="text-left">
      {_props.label && (
        <label className="block text-sm text-gray-700" htmlFor={_props.name}>
          {_props.label}
          {_props.required && <span className="text-red-500">*</span>}
        </label>
      )}

      <div className={`flex space-x-2 items-center ${className}`}>
        <textarea
          ref={ref}
          {..._props}
          rows={_props.rows || 3}
          className="w-full px-2 py-1 text-gray-700 border-gray-300 rounded disabled:bg-gray-50 disabled:text-gray-500"
        />
      </div>
    </div>
  )
})
TextArea.displayName = 'Text Area Input'
